<template>
  <div class="card mb-0">
    <div class="card-body">
      <px-editor
        ref="editor"
        v-if="!isLoading"
        :content="normalizeString(internalNote)"
        @on-text-change="onInternalNoteUpdate"
      />
    </div>
    <div class="card-footer text-end">
      <px-btn type="submit" :in-process="saveInProcess" :disabled="saveInProcess" @click.native="onSave">Save </px-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      singleProjectMessage: 'singleProject/message',
      singleProject: 'singleProject/project',
    }),
  },
  data() {
    return {
      internalNote: '',
      isLoading: true,

      saveInProcess: false,
    };
  },
  mounted() {
    this.isLoading = false;
    this.internalNote = this.singleProject.attributes.internal_note;
  },
  methods: {
    ...mapActions({
      singleProjectUpdate: 'singleProject/updateProject',
    }),
    onInternalNoteUpdate() {
      this.internalNote = this.$refs.editor.getContent();
    },
    async onSave() {
      this.saveInProcess = true;

      await this.singleProjectUpdate({
        id: this.singleProject.id,
        attributes: {
          internal_note: this.internalNote,
        },
      });

      this.saveInProcess = false;

      await this.v_toast(this.singleProjectMessage?.status, 'Updated successfully!');
    },
  },
};
</script>
